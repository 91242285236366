let children = [];

children.push({
    path: "/contabilidad/configuracionvalorizado",
    name: "ACO_CONFIGURATION_VALUED",
    component: () =>
        import ("../views/Accounting/aco_configuration_valued/AcoConfigurationValued.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Configuración de Valorizado", href: "/contabilidad/configuracionvalorizado" },
        ],
    },
});

children.push({
    path: "/contabilidad/buzon",
    name: "ACO_TRAY_VOUCHER",
    component: () =>
        import ("../views/Accounting/aco_tray_voucher/AcoTrayVoucher.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Buzón de Comprobantes", href: "/contabilidad/buzon" },
        ],
    },
});

children.push({
    path: "/contabilidad/scanvoucher",
    name: "ACO_VOUCHER_SCAN",
    component: () =>
        import ("../views/Accounting/aco_voucher_scan/AcoVoucherScan.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Scanerar voucher", href: "/contabilidad/scanvoucher" },
        ],
    },
});

children.push({
    path: "/contabilidad/scanfiles",
    name: "ACO_SCAN_FILE",
    component: () =>
        import ("../views/Accounting/aco_scan_file/AcoScanFile.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Scanerar voucher", href: "/contabilidad/scanfiles" },
        ],
    },
});

children.push({
    path: "/contabilidad/diarocontabilidad",
    name: "ACO_DIARY_ACCOUNTING",
    component: () =>
        import ("../views/Accounting/aco_diary_accounting/AcoDiaryAccounting.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Diario de Contabilidad", href: "/contabilidad/diarocontabilidad" },
        ],
    },
});

children.push({
    path: "/contabilidad/provisiones",
    name: "ACO_PROVISIONS",
    component: () =>
        import ("../views/Accounting/aco_provisions/AcoProvisions.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Provisiones", href: "/contabilidad/provisiones" },
        ],
    },
});


children.push({
    path: "/contabilidad/periodosmensuales",
    name: "ACO_PERIOD_VALUE",
    component: () =>
        import ("../views/Accounting/aco_period_valued/AcoPeriodValued.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Periodos Mensuales", href: "/contabilidad/periodosmensuales" },
        ],
    },
});

children.push({
    path: "/contabilidad/presupuestoactual",
    name: "ACO_MY_BUDGET",
    component: () =>
        import ("../views/Accounting/aco_budget/BudgetIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Presupuesto Actual", href: "/contabilidad/presupuestoactual" },
        ],
    },
});

children.push({
    path: "/contabilidad/valorizado",
    name: "ACO_BUDGET_VALUED",
    component: () =>
        import ("../views/Accounting/aco_budget_valued/AcoBudgetValued.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Valorizado", href: "/contabilidad/valorizado" },
        ],
    },
});
children.push({
    path: "/contabilidad/consolidado",
    name: "ACO_MY_BUDGET_CONSOLIDATED",
    component: () =>
        import ("../views/Accounting/aco_budget_valued/AcoBudgetValuedConsolidated.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Consolidado", href: "/contabilidad/consolidado" },
        ],
    },
});


children.push({
    path: "/contabilidad/maestros/editarpresupuesto",
    name: "ACO_BUDGET_EDIT",
    component: () =>
        import ("../views/Accounting/aco_budget/AcoBudgetAdd.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Editar Presupuesto", href: "/contabilidad/maestros/editarpresupuesto" },
        ],
    },
});

children.push({
    path: "/contabilidad/maestros/crearpresupuesto",
    name: "ACO_BUDGET_CREATE",
    component: () =>
        import ("../views/Accounting/aco_budget/create/AcoBudgetCreate.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Crear Presupuesto", href: "/contabilidad/maestros/crearpresupuesto" },
        ],
    },
});



children.push({
    path: "/contabilidad/maestros/tipodecambio",
    name: "ACO_EXCHANGE_RATE",
    component: () =>
        import ("../views/Accounting/aco_exchange_rate/AcoExchangeRate.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Tipo de Cambio", href: "/contabilidad/maestros/tipodecambio" },
        ],
    },
});


children.push({
    path: "/contabilidad/maestros/bloqueargastofijo",
    name: "ACO_IGNORE_GF",
    component: () =>
        import ("../views/Accounting/aco_ignore_gf/AcoIgnoreGf.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Bloquear Gasto Fijo", href: "/contabilidad/maestros/bloqueargastofijo" },
        ],
    },
});


children.push({
    path: "/contabilidad/maestros/bloquearceco",
    name: "ACO_IGNORE_CENTERCOSTE_GF",
    component: () =>
        import ("../views/Accounting/aco_ignore_centercoste_gf/AcoIgnoreCenterCosteGf.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Bloquear Centro de Costo", href: "/contabilidad/maestros/bloquearceco" },
        ],
    },
});

children.push({
    path: "/contabilidad/maestros/bloquearcuentacontable",
    name: "ACO_IGNORE_ACCOUNT_GF",
    component: () =>
        import ("../views/Accounting/aco_ignore_account_gf/AcoIgnoreAccountGf.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Bloquear Cuenta Contable", href: "/contabilidad/maestros/bloquearcuentacontable" },
        ],
    },
});

children.push({
    path: "/contabilidad/gastospermanentes",
    name: "ACO_RGP",
    component: () =>
        import ("../views/Accounting/aco_budget_valued/AcoBudgetValuedRgp.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "RGP", href: "/contabilidad/gastospermanentes" },
        ],
    },
});

children.push({
    path: "/contabilidad/maestros/periodosanuales",
    name: "ACO_PERIOD_YEAR",
    component: () =>
        import ("../views/Accounting/aco_period_year/AcoPeriodYear.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Periodos Anuales", href: "/contabilidad/maestros/periodosanuales" },
        ],
    },
});


export { children };