<template>
    <div>
        <!-- <vc-loading :processing="processing" :errors="errors"  /> -->
        <vc-header-crud class="pb-1" :title="title" :add="add" :save="save" :edit="edit && this.selected.length > 0"
            :remove="
        remove &&
        (this.selected.length > 0 ? this.selected[0].secStatus : 0) == 1
      " :view="view" :close="close" :excel="excel" @excel="exportExcel" @add="$emit('add')" @save="$emit('save')"
            @edit="ediData" @remove="removed" @view="$emit('view')" @close="$emit('close')" v-if="!noHeader">
            <template v-slot:options>
                <slot name="options"> </slot>
            </template>
        </vc-header-crud>

        <v-card elevation="0" class="pl-2 pb-2 pr-2 mr-0 mt-1" style="border-radius: 0px">
            <div>
                <v-row style="margin: auto" class="mb-3 mt-0">
                    <slot style="z-index: -999" name="filter"> </slot>
                </v-row>
            </div>

            <div>
                <!-- <v-row style="margin: auto"> </v-row> -->
                <v-card elevation="0" class="mt-0" outlined>
                    <v-col cols="12" class="mt-0" v-if="!noSearch">
                        <vc-text single-line :prepend-icon="'mdi-magnify'" placeholder="Digite texto a buscar"
                            v-model="searchText" clearable></vc-text>
                    </v-col>

                    <!-- <v-data-table
                        ref="datatable"
                        style="border-radius: 0px"
                        class="elevation-0"
                        elevation="0"
                        v-model="selected"
                        :height="height"
                        :headers="config.headers"
                        :items="desserts"
                        :item-value="propID"
                        :items-per-page="10"
                        :items-per-page-options="itemsPerPage"
                        density="compact"
                        :loading="loading"
                        :items-length="total"
                        page="2"
                        loading-text="Cargando"
                        no-data-text="Sin Datos"
                        @update:options="handleFooterPropsUpdate"
                        @click:row="handleClick"
                        :fixed-header="true"
                        :hover="true"
                        :pageCount="1000"
                    >
                        <template v-for="p in getProps()" v-slot:[getNameSlot(p)]="{ item }">
                            <slot :name="p" :row="item.selectable">{{ formatItem(item.selectable[p], config.model[p]) }} </slot>
                        </template>
                    </v-data-table> -->
                    <v-data-table style="border-radius: 0px" class="elevation-0" elevation="0" v-model="selected"
                        :show-select="!singleRow" :single-select="singleSelect" :disable-sort="!sortable"
                        :mobile-breakpoint="mobile" :hide-default-footer="noFooter"
                        :height="$vuetify.breakpoint.xs ? 'auto' : height" :headers="config.headers" :items="desserts"
                        @item-selected="selectedCheckSlot($event)" @toggle-select-all="toggleSelectAll"
                        @dblclick:row="doubleClickFunc" @click:row="rowClick" :options.sync="options" :item-key="propID"
                        :group-by="groupBy" :footer-props="{
                            showCurrentPage: true,
                            showFirstLastPage: true,
                            itemsPerPageOptions: itemsPerPage,
                        }" :server-items-length="total" :loading="loading" :single-expand="true" :fixed-header="true" dense
                        loading-text="Cargando" no-data-text="Sin Datos">
                        <template v-for="p in getProps()" v-slot:[getNameSlot(p)]="{ item }">
                            <slot :name="p" :row="item">{{ formatItem(item[p], config.model[p]) }}
                            </slot>
                        </template>
                        <!-- <template v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer :pagination="pagination" :options="options" @update:options="updateOptions" items-per-page-text="$vuetify.dataTable.itemsPerPageText" />
                    </template> -->
                    </v-data-table>
                </v-card>
            </div>
        </v-card>

        <vc-footer></vc-footer>

        <vc-loading :processing="processing" :errors="errors" />
    </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
    components: {},
    name: "",

    props: {
        title: {
            type: String,
            default: "Vuetify Confirm Dialog Example",
        },

        config: {
            type: Object,
            default: null,
            required: true,
        },

        filter: {
            type: Object,
            default: null,
            required: true,
        },
        height: {
            type: String,
            default: "auto",
        },

        noSearch: {
            type: Boolean,
            default: false,
        },
        crud: {
            type: Boolean,
            default: true,
        },

        //Buttons
        add: {
            type: Boolean,
            default: false,
        },
        save: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        remove: {
            type: Boolean,
            default: false,
        },
        view: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: false,
        },
        excel: {
            type: Boolean,
            default: false,
        },

        singleRow: {
            type: Boolean,
            default: true,
        },
        rowDefault: {
            type: Boolean,
            default: true,
        },
        noHeader: {
            type: Boolean,
            default: false,
        },
        noCompanyFilter: {
            type: Boolean,
            default: false,
        },

        sortable: {
            type: Boolean,
            default: false,
        },

        singleSelect: {
            type: Boolean,
            default: false,
        },

        mobile: {
            type: Number,
            default: 100,
        },
        noFooter: {
            type: Boolean,
            default: false,
        },

        groupBy: null,

        noFull: {
            type: Boolean,
            default: false,
        },

        notFilter:{
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            row: {},
            itemsPerPage: [5, 10, 25, 50, -1],
            total: 0,
            parametersPagination: {
                filter: {
                    CpyID: 0,
                },
                draw: 1,
                start: 0,
                length: 10,
                order: [{
                    dir: "asc",
                    column: "",
                }, ],
                search: "",
            },
            desserts: [],
            options: {},
            selected: [],

            processing: false,
            errors: null,

            loading: true,

            searchText: "",
            selectedRow: null,

            //Loading
            processing: false,
            errors: null,
        };
        /* search: "", */
    },
    watch: {
        filter: {
            handler() {
                if(!this.notFilter){
                    this.options.page = 1;
                    this.selected = [];
                    this.getData();
                }
                
            },
            deep: true,
        },

        options: {
            handler() {
                this.getData();
            },
        },
        searchText() {
            this.debouncedGetAnswer();
        },
    },
    methods: {
        getNameSlot(p) {
            return "item." + p;
        },
        getProps() {
            let r = [];
            for (var prop in this.config.model) r.push(prop);
            return r;
        },

        formatItem(value, prop) {
            let val = value;
            if (prop == "date") val = this.$fun.formatDateDB(val);
            if (prop == "time") val = this.$fun.formatTimeDB(val);
            if (prop == "datetime") val = this.$fun.formatDateTimeDB(val);
            return val;
        },

        //Get Data
        getDataFromApi() {
            this.processing = true
            this.loading = true;
            return new Promise((resolve, reject) => {
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;

                this.parametersPagination.start =
                    (this.options.page - 1) * this.parametersPagination.length;
                this.parametersPagination.filter = this.filter;

                if (!this.noCompanyFilter) {
                    if (this.parametersPagination.filter == null) {
                        this.parametersPagination.filter = {
                            cpyID: this.$fun.getCompanyID(),
                        };
                    } else {
                        this.parametersPagination.filter.cpyID = this.$fun.getCompanyID();
                    }
                }

                this.parametersPagination.length = itemsPerPage;

                if (this.options.sortBy.length > 0) {
                    this.parametersPagination.order = [{
                        column: this.options.sortBy[0].key,
                        dir: this.options.sortBy[0].order,
                    }, ];
                }

                this.config.service
                    .pagination(this.parametersPagination)
                    .then((response) => {
                            this.selected = [];

                            let items = response.data.data.data;
                            console.log("items pagination-> ", items);
                            const total = response.data.data.recordsTotal;
                            setTimeout(() => {
                                this.loading = false;
                                this.processing = false
                                resolve({
                                    items,
                                    total,
                                });
                            }, 0);
                        },
                        (e) => {
                            this.errors = e.response
                            /* this.processing = false;
                            this.loading = false; */
                            let items = [];
                            const total = 0;

                            setTimeout(() => {
                                this.loading = false;
                                this.processing = false
                                resolve({
                                    items,
                                    total,
                                });
                            }, 0);
                        }
                    );
            });
        },

        getData() {
            this.selected = [];
            this.getDataFromApi().then((data) => {
                this.desserts = data.items;
                this.total = data.total;
                if (this.desserts.length > 0) this.refresh(this.desserts[0]);
            });
        },

        refresh(obj) {
            this.selected = [];
            if (obj == null || obj == undefined) {
                this.getDataFromApi().then((data) => {
                    this.desserts = data.items;
                    this.total = data.total;
                });
            } else if (this.propID != null) {
                let ob = this.desserts.find((x) => obj[this.propID] == x[this.propID]);
            }
        },

        /*         handleClick(index, item) {
                this.selectedRow = item.item.index;

                if (this.singleRow) {
                    this.selected = [item.item.selectable];
                    this.row = Object.assign({}, item.item.selectable);
                } else {
                    if (
                        this.selected.find((x) => {
                            return x == item;
                        }) != null
                    )
                        this.selected = this.selected.filter((x) => {
                            return x != item.item.selectable;
                        });
                    else {
                        this.selected.push(item);
                        this.row = Object.assign({}, item.item.selectable);
                    }
                }
                this.$emit("rowSelected", this.selected); //Click en la Fila
            }, */

        removed(value) {
            let request = Object.assign({},
                this.selected.length > 0 ? this.selected[0] : {}
            );
            request.secStatus = 0;
            request.usrUpdateID = this.$fun.getUserID();

            if (request[this.propID] > 0) {
                let messahe = this.$const.MSG_DELETE + " | " + request[this.propID];

                this.$fun.sweetAlert(messahe, "question").then((val) => {
                    if (val.value) {
                        this.processing = true;
                        this.config.service.delete(request).then(
                            (res) => {
                                this.processing = false;
                                this.$fun.sweetAlert(this.$const.MSG_SUCCESS_DELETE, "success");
                                this.refresh();
                            },
                            (e) => {
                                this.errors = e.response;
                                this.processing = false;
                            }
                        );
                    }
                });
            }

            /* 
                        if (!this.deleteNoFunction) {
                            let obj = Object.assign({}, this.selected.length > 0 ? this.selected[0] : this.row);

                            obj.SecStatus = value;
                            obj.UsrUpdateID = this.$fun.getUserID();
                            obj.SecUpdate = this.$fun.getDate()

                            if (obj[this.propID] > 0) {
                                let Text = this.$const.MSG_003;
                                this.$fun.alert(Text, "question", this.alertType, localStorage.getItem('AlertAlign')).then((val) => {
                                    if (val.value) {
                                        this.config.service.delete(obj, this.$fun.getUserID()).then((res) => {
                                            this.refresh()
                                            this.$emit('close')
                                            this.$fun.alert(this.$const.MSG_005, "success");
                                        });
                                    }
                                });
                            }

                        } else this.$emit("delete", this.row); */
        },

        getAnswerHead() {
            if (this.searchText == null) this.searchText = "";
            this.searchEvent(this.searchText);
        },

        searchEvent(search) {
            this.parametersPagination.search = search;
            this.options.page = 1;

            this.getData();
            this.$emit("filterSearch", this.searchText);
        },

        //All Items
        toggleSelectAll({
            items,
            value
        }) {
            if (value) this.selected = items;
            else this.selected = [];
            this.$emit("rowSelected", this.selected);
        },

        //Double Click
        doubleClickFunc: function (mouse, row) {
            this.$emit("doubleClick", row.item);
            this.$emit("dblClick", row.item);
        },

        //Fila
        rowClick: function (item, row) {
            if (this.singleRow) {
                this.selected = [item];
                this.row = Object.assign({}, item);
            } else {
                if (
                    this.selected.find((x) => {
                        return x == item;
                    }) != null
                )
                    this.selected = this.selected.filter((x) => {
                        return x != item;
                    });
                else {
                    this.selected.push(item);
                    this.row = Object.assign({}, item);
                }
            }
            this.$emit("rowSelected", this.selected); //Click en la Fila
        },

        ediData() {
            this.$emit('edit', this.selected.length > 0 ? this.selected[0] : {})
        },

        exportExcel() {
            this.$fun.exportExcel(this.desserts);
        }
    },
    computed: {
        propID() {
            for (var prop in this.config.model)
                if (this.config.model[prop] == "ID") return prop;
            return null;
        },
    },

    created() {
        let filterHeaders = [];
        if (this.noFull && this.noFooter) this.itemsPerPage = [200];
        if (this.noFull && !this.noFooter) this.itemsPerPage = [5, 10, 50, 200];
        /* if (this.crud) filterHeaders.push({ title: 'Acciones', key: 'actions', sortable: false, width: 10 }); */

        this.config.headers.forEach((element) => {
            filterHeaders.push(element);
        });

        this.config.headers = filterHeaders;
        this.debouncedGetAnswer = _.debounce(this.getAnswerHead, 700);
    },
    destroyed() {},
};
</script>

<style scoped>
.selected-row {
    background-color: #057685;
    /* Cambia el color de fondo según tus preferencias */
}
</style>
